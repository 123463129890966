/* SearchBar.css */

.search-bar-container {
  display: flex;
  justify-content: flex-start; /* Alinear el buscador a la izquierda */
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.search-bar {
  width: 200px;
  max-width: 100%;
  position: relative;
}

.search-bar input {
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search-bar input:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}
