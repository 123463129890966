/* Global styles */
* {
    text-decoration: none;
}
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Poppins', Arial, sans-serif;
    background-color: #f9f9f9;
    color: #2c3e50; /* Gris oscuro */
}

/* Profile section styles */
header {
    width: 100%;
    padding: 40px 0;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    border-bottom: 2px solid #2c3e50; /* Línea en la parte inferior en gris oscuro */
}

.profile-card {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Asegura que el logo y la información estén bien distribuidos */
    max-width: 1400px; /* Ampliar el ancho máximo */
    width: 90%;
    margin: 0 auto;
    padding: 0 20px; /* Añade un poco de padding para separación interior */
}

.profile-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Alinea el texto a la izquierda */
    width: 100%;
    padding-left: 20px; /* Añade espacio entre la imagen y el texto */
}

.store-name {
    margin: 0;
    font-size: 2.5rem;
    color: #2c3e50; /* Gris oscuro */
}

.social-links {
    margin: 10px 0;
    display: flex;
    gap: 10px; /* Espacio entre los iconos */
}

.social-links a {
    color: #2c3e50; /* Gris oscuro */
    font-size: 1.8rem;
    text-decoration: none;
}

.social-links a:hover {
    color: #2de2bb; /* Dorado en hover */
}

.store-description {
    font-size: 1.1rem;
    text-align: justify;
    max-width: 1200px;
    color: #7f8c8d; /* Gris medio */
    margin-top: 5px;
}

/* Container for products */
.container {
    max-width: 1400px; /* Ampliar el ancho máximo */
    margin: 0 auto;
    padding: 20px;
}

/* Product Grid styles */
.product-grid-container {
    margin-top: 40px;
}

.product-section {
    margin-bottom: 50px;
}

.product-section h2 {
    margin-bottom: 20px;
    font-size: 1.75rem;
    color: #2c3e50; /* Gris oscuro */
    border-bottom: 2px solid #2c3e50; /* Borde inferior en gris oscuro */
    padding-bottom: 10px;
    letter-spacing: 0.5px;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Mostrar 4 productos por línea */
    gap: 25px;
}

.product-card {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.product-card img {
    width: 100%;
    height: auto;
    max-height: 175px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.product-card img:hover {
    transform: scale(1.05);
}

/* Information Section */
.product-card h3 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 10px;
    min-height: 50px; /* Consistent title height */
}

.product-card p {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 20px;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limits to 4 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; /* Adjust this based on your font size */
    min-height: 6em; /* 4 lines of text */
    max-height: 6em; /* 4 lines of text */
}
/* Tooltip Styles */
.product-card p:hover::after {
    content: attr(title); /* Use the title attribute for tooltip */
    position: absolute;
    bottom: 100%; /* Position above the text */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 1000;
    font-size: 0.8rem;
    width: max-content;
    max-width: 200px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    pointer-events: none; /* Ensure the tooltip does not interfere with hovering */
}

.product-card p:hover {
    text-overflow: clip; /* Shows full text on hover */
}

/* Price and Discount */
.product-card .price {
    font-size: 1.5rem;
    color: #e74c3c;
    margin-bottom: 15px;
}

.product-card .discount {
    font-size: 1rem;
    color: #27ae60;
}

/* Purchase Button */
.product-card button {
    background: linear-gradient(135deg, #2c3e50, #6f42c1);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: auto; /* Keeps button at the bottom */
}

.product-card button:hover {
    transform: translateY(-3px);
    background: linear-gradient(135deg, #2de2bb, #6f42c1);
}


/* Mejorar espaciado y disposición de los botones de navegación secundaria */
.load-controls {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.load-controls .load-more,
.load-controls .show-less {
    background-color: #ecf0f1;
    color: #2c3e50; /* Gris oscuro */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.load-controls .load-more:hover,
.load-controls .show-less:hover {
    background-color: #bdc3c7;
    transform: translateY(-1px);
    color: white;
}

/* Media Queries para Responsividad */
@media screen and (max-width: 768px) {
    .profile-card {
        flex-direction: column; /* Coloca el logo arriba y la info abajo */
        align-items: center;
        text-align: center;
    }
    .product-section {
        margin-bottom: 80px;
    }
    .profile-image {
        margin-bottom: 20px; /* Añade un espacio entre la imagen y el texto cuando esté en columna */
    }

    .profile-info {
        align-items: center; /* Centra el texto y los enlaces sociales */
        padding-left: 0; /* Elimina el padding lateral */
    }

    .social-links {
        justify-content: center; /* Centra los iconos de las redes sociales */
    }

    .product-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Ajusta el tamaño mínimo de la columna */
        gap: 15px; /* Reduce el espacio entre productos */
    }

    .product-card {
        padding: 20px; /* Reduce el padding interno */
    }

    .product-card img {
        max-width: 80%; /* Reduce el tamaño de la imagen */
        margin-bottom: 15px; /* Reduce el margen inferior */
    }

    .product-card h3 {
        font-size: 1.2rem; /* Reduce el tamaño de la fuente del título */
        margin-bottom: 10px; /* Reduce el margen inferior */
    }

    .product-card p {
        display: none; /* Oculta las descripciones en móviles */
    }

    .product-card .price {
        font-size: 1.3rem; /* Reduce el tamaño de la fuente del precio */
        margin-bottom: 10px; /* Reduce el margen inferior */
    }

    .product-card .discount {
        font-size: 0.9rem; /* Reduce el tamaño de la fuente del descuento */
    }

    .product-card button {
        padding: 8px 16px; /* Reduce el tamaño del botón */
        font-size: 0.9rem; /* Reduce el tamaño de la fuente del botón */
    }
}
/* PickOfTheDay.css */

.pick-of-the-day {
    padding: 20px 0;
    margin-bottom: 40px;
    text-align: left;
}

.pick-of-the-day h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
}

.product-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff; /* Fondo blanco como el de los productos */
    border-radius: 10px; /* Bordes redondeados */
    padding: 20px; /* Espaciado interno */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera para darle relieve */
    flex-direction: row;
}

.product-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.product-image {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
}

.product-info {
    flex: 1;
    padding-left: 40px;
}

.product-info h3 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
}

.product-info p {
    font-size: 1.2rem;
    color: #7f8c8d;
    margin-bottom: 15px;
}

.price {
    font-size: 1.6rem;
    color: #e74c3c;
    margin-bottom: 20px;
}

.discount {
    font-size: 1rem;
    color: #27ae60;
}

.buy-button {
    background: linear-gradient(135deg, #2c3e50, #6f42c1);
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.buy-button:hover {
    transform: translateY(-3px);
    background: linear-gradient(135deg, #2de2bb, #6f42c1);
}

/* Media Queries para Responsividad */

/* Para pantallas de tabletas (ancho máximo de 768px) */
@media screen and (max-width: 768px) {
    .product-details {
        flex-direction: column; /* Cambia la dirección a columna para pantallas pequeñas */
        align-items: center; /* Alinea los elementos en el centro */
    }

    .product-info {
        padding-left: 0; /* Elimina el padding lateral */
        padding-top: 20px; /* Añade un padding superior para separar del producto */
        text-align: center; /* Centra el texto */
    }

    .product-info h3 {
        font-size: 1.8rem; /* Reduce el tamaño de la fuente del título */
    }

    .product-info p {
        font-size: 1rem; /* Reduce el tamaño de la fuente de la descripción */
    }

    .price {
        font-size: 1.4rem; /* Reduce el tamaño de la fuente del precio */
    }

    .discount {
        font-size: 0.9rem; /* Reduce el tamaño de la fuente del descuento */
    }

    .buy-button {
        padding: 8px 16px; /* Reduce el tamaño del botón */
        font-size: 1rem; /* Reduce el tamaño de la fuente del botón */
    }
}

/* Para pantallas de móviles (ancho máximo de 580px) */
@media screen and (max-width: 580px) {
    .product-image {
        max-width: 100%; /* La imagen ocupa todo el ancho disponible */
        border-radius: 5px; /* Reduce el borde redondeado */
    }

    .product-info h3 {
        font-size: 1.6rem; /* Ajusta el tamaño de la fuente para pantallas pequeñas */
    }

    .product-info p {
        font-size: 0.9rem; /* Ajusta el tamaño de la fuente para la descripción */
    }

    .price {
        font-size: 1.2rem; /* Ajusta el tamaño del precio */
    }

    .buy-button {
        padding: 6px 12px; /* Ajusta el padding del botón para pantallas pequeñas */
        font-size: 0.9rem; /* Reduce el tamaño de la fuente del botón */
    }
}

  /* styles.css */
.star-rating {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #ffc107; /* Color dorado para las estrellas */
  }
  
  .star {
    margin-right: 4px;
  }
  
  .star.full {
    color: #ffc107;
  }
  
  .star.half {
    background: linear-gradient(90deg, #ffc107 50%, #e4e5e9 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .star.empty {
    color: #e4e5e9;
  }
  
  .reviews {
    margin-left: 8px;
    font-size: 0.9rem;
    color: #7f8c8d; /* Gris medio para el número de reseñas */
  }

/* Modern and Elegant Comment Section */
.product-comments {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff; /* Clean white background */
  border-radius: 12px; /* Soft rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
}

.product-comments h4 {
  font-size: 1.3rem; /* Slightly larger heading for emphasis */
  color: #2c3e50; /* Darker text for contrast */
  margin-bottom: 15px;
  font-weight: 700; /* Bold for emphasis */
  text-align: center; /* Center-align for balance */
  letter-spacing: 0.75px; /* Light letter spacing */
}

.comment {
  margin-bottom: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid #eaeaea; /* Light border to separate comments */
  font-size: 1rem; /* Uniform font size for readability */
  background-color: #f7f8fa; /* Light grey background for contrast */
  border-radius: 8px; /* Slightly rounded corners */
}

.comment:last-child {
  margin-bottom: 0;
  border-bottom: none; /* No border after the last comment */
}

.comment strong {
  color: #555; /* Slightly darker text for the username */
  font-weight: 600; /* Semi-bold for distinction */
  font-size: 1rem;
  margin-right: 8px;
}

.comment p {
  display: inline-block;
  color: #666; /* Softer color for comment text */
  font-size: 1rem;
  line-height: 1.6;
  margin: 0; /* Remove default margins for a cleaner look */
}

/* Button styling for consistency */
.buy-button {
  background-color: #6f42c1;
  color: #fff;
  padding: 12px 25px; /* Increased padding for a more luxurious feel */
  border: none;
  border-radius: 6px; /* Slightly more rounded for a softer appearance */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effects */
  margin-top: 20px;
  display: block; /* Ensure the button takes full width */
  width: 100%; /* Full-width button */
  text-align: center;
}

.buy-button:hover {
  background-color: #5a379e;
  transform: translateY(-2px); /* Slight lift on hover */
}

.product-grid-search {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columnas por defecto */
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Media Queries para Responsividad */
  
  /* Para pantallas de tabletas (ancho máximo de 768px) */
  @media screen and (max-width: 768px) {
    .product-grid-search {
      grid-template-columns: repeat(1, 1fr); /* 2 columnas en pantallas medianas */
    }
  }
  
  /* Para pantallas de móviles (ancho máximo de 480px) */
  @media screen and (max-width: 480px) {
    .product-grid-search {
      grid-template-columns: 1fr !important; /* Forzar 1 columna en pantallas pequeñas */
    }
  }
  /* components/Footer.css */
.footer {
    background-color: #2c3e50; /* Fondo gris oscuro */
    color: #ffffff; /* Texto blanco */
    text-align: center;
    padding: 20px 0; /* Espaciado vertical */
    position: relative;
    bottom: 0;
    width: 100%;
    font-size: 0.9rem; /* Tamaño de fuente más pequeño */
    font-family: 'Poppins', Arial, sans-serif; /* Asegúrate de que la fuente sea consistente */
  }