.article-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .article-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .article-image-container {
    width: 100%;
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    margin-bottom: 30px;
  }
  
  .article-image-fixed-height {
    width: 100%;
    height: 200px; /* Altura fija */
    object-fit: cover; /* Ajustar la imagen sin distorsionar */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .article-content {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  .products-section {
    margin-top: 30px;
  }
  
  .products-section h3 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .article-footer {
    text-align: center;
    margin-top: 30px;
  }
  
  .back-link {
    text-decoration: none;
    color: #2c3e50;
    font-weight: bold;
    font-size: 1.1rem;
  }
  