/* Estilos para la página del blog */
.blog-page {
    font-family: 'Poppins', Arial, sans-serif;
    color: #2c3e50; /* Gris oscuro */
    padding: 40px 20px;
    min-height: 100vh;
  }
  
  /* Encabezado simplificado */
  .blog-header {
    text-align: center;
    background-color: white;
    color: #2c3e50;
    border-radius: 10px;
    margin-bottom: 50px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05); /* Sombra muy suave */
  }
  
  .blog-header h1 {
    font-size: 3rem; /* Tamaño grande pero limpio */
    font-weight: 700;
    margin: 0;
    color: #2c3e50;
    letter-spacing: 1px;
  }
  
  /* Estructura del contenido del blog */
  .blog-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Cambia a dos columnas */
    gap: 40px;
    padding: 0 20px;
  }
  
  /* Estilos de las tarjetas del blog */
  .blog-post {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05); /* Sombra ligera */
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-post:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Sombra suave al pasar el cursor */
  }
  
  .blog-post img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-text {
    padding: 20px;
    text-align: left;
  }
  
  .blog-text h2 {
    font-size: 1.6rem;
    color: #2c3e50;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  .blog-text p {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 20px;
  }
  
  /* Botón de leer más */
  .read-more {
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  

  
  /* Footer limpio */
  .blog-footer {
    text-align: center;
    padding: 30px;
    background-color: #2c3e50;
    color: white;
    border-radius: 10px;
    margin-top: 50px;
  }
  
  /* Media Queries para responsividad */
  @media screen and (max-width: 768px) {
    .blog-header h1 {
      font-size: 2.5rem;
    }
  
    .blog-content {
      grid-template-columns: 1fr;
    }
  }
  