/* Estilos para el icono de "hamburger" */
.hamburger {
    width: 40px;
    height: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px;
    position: fixed;
    top: 20px;
    left: 15px;
    z-index: 1000; /* Asegura que esté siempre encima del menú */
  }
  
  .hamburger .line {
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease;
  }
  
  .hamburger.open .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburger.open .line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  /* Estilos del menú lateral más grande */
  .side-menu {
    position: fixed;
    top: 0;
    left: -300px; /* Cambiado para que el menú sea más grande */
    width: 300px; /* Aumenta el ancho del menú */
    height: 100%;
    background-color: #1c1c1c; /* Color oscuro */
    color: white;
    padding-top: 60px;
    transition: 0.3s ease-in-out;
    z-index: 999; /* Justo debajo del icono de "hamburger" */
  }
  
  .side-menu.open {
    left: 0; /* Se desliza hacia la pantalla cuando está abierto */
  }
  
  /* Estilos para el botón "X" movido a la derecha */
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px; /* Mueve el botón "X" a la derecha */
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  .hamburger.open {
    display: none;
  }
  .close-btn:hover {
    color: #ff0000; /* Cambia a rojo al pasar el cursor */
  }
  
  /* Listado del menú */
  .side-menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .side-menu ul li {
    padding: 20px;
    text-align: left;
    font-family: 'Poppins', sans-serif; /* Fuente moderna */
    font-weight: 500;
    font-size: 1.2rem;
  }
  
  .side-menu ul li a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 10px 0;
    transition: background-color 0.3s ease, padding-left 0.3s ease;
  }
  
  .side-menu ul li a:hover {
    background-color: #333; /* Cambia a un gris más claro al hacer hover */
    padding-left: 10px; /* Efecto de desplazamiento */
    border-left: 4px solid #6f42c1; /* Barra morada a la izquierda */
  }
  